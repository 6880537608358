import React, {useState, useEffect} from 'react'
import {Table} from 'react-bootstrap'
import DashboardNavigator from '../../partials/DashboardNavigator'
import NavigationBar from '../../partials/NavigationBar'
import AddUser from './AddUser'
import EditUser from './EditUser'
import { Row,Col } from 'react-bootstrap'
import { getOrgAdminUserList, removeOrgAdminUser,resetCSVjson } from '../../../redux-store/actions/orgAdminActions'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { Redirect } from 'react-router'
import { exportOrgAdminData } from '../../../redux-store/actions/orgAdminActions'
import { user_fields } from '../../../shared'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import RemoveUserConfirmation from './RemoveUserConfirmation'
import Loading from '../../partials/Loading'
import Pagination from '../../partials/Pagination'
import {
    useJsonToCsv
  } from 'react-json-csv'

const UserList = () => {

    const [addUserModalShow, setAddUserModalShow] = useState(false)
    const [editUserModalShow, setEditUserModalShow] = useState(false)
    const [removeUserModalShow, setRemoveUserModalShow] = useState(false)
    const [rowClicked, setRowClicked] = useState(false)
    const [rowId, setRowId] = useState('')
    const [editableFirstName, setEditableFirstName]= useState('')
    const [editableSurname, setEditableSurname]= useState('')
    const [editableEmail, setEditableEmail]= useState('')
    const [manageUser, setManageUser]=useState()
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [userExportId, setUserExportId] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10)
    const [errorMsg, setErrorMsg] = useState('')

    const {users, useroptions, fetching_user_list} = useSelector((state)=>state.orgAdminUserList)
    const {exportable_json, exporting, export_data_received} = useSelector((state)=>state.orgAdminExportData)
    const dispatch = useDispatch()


    useEffect(() => {
        async function getUserList(){
            await dispatch(getOrgAdminUserList(currentPage, usersPerPage))
        }
        getUserList()
    }, [removeOrgAdminUser, manageUser, currentPage])

    const handleRemoveConfirmation = ()=>{
        setRemoveUserModalShow(true)
    }
    const handleRemove = ()=>{
        dispatch(removeOrgAdminUser(rowId))
        clearSelectedRow()
        setManageUser(Math.random())
    }

    const handleEdit = ()=>{
        setEditUserModalShow(true)
    }

    const manageUserList = ()=>{
        setManageUser(Math.random())
    }
    const handleRowClick = (row_id, first_name, surname, email) =>{
        setRowId(row_id)
        setEditableFirstName(first_name)
        setEditableSurname(surname)
        setEditableEmail(email)
        setRowClicked(true)
    }

    const clearSelectedRow = ()=>{
        setRowClicked(false)
        setRowId(false)
        setEditableFirstName('')
        setEditableSurname('')
        setEditableEmail('')
    }

    const { saveAsCsv } = useJsonToCsv()

    const filename = 'exported-result'

    const exportData = async ()=>{
      if(toDate == null && typeof(toDate) == "undefined"){
        setErrorMsg("Please select a time period.")
        return
      }
      if(fromDate == null && typeof(fromDate) == "undefined"){
        setErrorMsg("Please select a time period.")
        return
      }
      if(toDate.length <= 0){
        setErrorMsg("Please select a time period.")
        return
      }
      if(fromDate.length <= 0){
        setErrorMsg("Please select a time period.")
        return
      }
      setErrorMsg("")

      await dispatch(exportOrgAdminData(moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD"),
          userExportId))

    }

    const downloadData = (data)=>{
        const fields = user_fields
        if(data && data.length!==0) {

            saveAsCsv({ data, fields, filename })
        }
        else alert("No results")

        dispatch(resetCSVjson())
    }

    const handleToDateChange = date => {
      setErrorMsg("")
      setToDate(date)
    }

    const handleFromDateChange = date => {
      setErrorMsg("")
      setFromDate(date)
    }
     // Change page
    const paginate = pageNumber => {
          setCurrentPage(pageNumber)
          console.log(currentPage)
    }

    // Get current users page

    const totalUsers = JSON.parse(localStorage.getItem('pdxTableTotalRows'))

    return (
        <div>
            {!localStorage.getItem('userInfo') && <Redirect to='/login' />}
            <NavigationBar />
            <DashboardNavigator
                btn1={'Organization Dashboard'}
                link1={'/org-admin/org-dashboard'}
                btn2={'User List'}
                link2={'/org-admin/userlist'}
                comp={'userlist'}
             />
            {useroptions &&
            <div className='orglist-card separator'>


                <Row>
                <p className="dataexport">Data Export</p>
                    <Col sm='1'></Col>
                    <Col sm='3'>
                        <div className='export-label'>From</div>
                             <DatePicker selected={fromDate}
                                onChange={handleFromDateChange}
                                dateFormat="yyyy-MM-dd"
                             />
                    </Col>
                    <Col sm='3'>
                            <div className='export-label'>To</div>
                            <DatePicker
                            selected={toDate}
                            onChange={handleToDateChange}
                            dateFormat="yyyy-MM-dd"
                            />
                    </Col>
                    <Col sm='3'>
                        <div className='export-label'>User</div>
                        <select
                          className='select-dark'
                          value={userExportId}
                          style={{padding: "8px"}}
                          onChange={e => setUserExportId(e.target.value)}>
                          {useroptions.map(option => (
                            <option key={option.value} value={option.value}
                            >{option.label}</option>
                          ))}
                        </select>
                    </Col>
                </Row>
                {!exporting?
                  <div>
                  <div className="export-error">{errorMsg}</div>
                  <div className='export-btn separator'>
                  <button
                      onClick={exportData}
                      className='protondx-btn-primary btn-sm'>Export Results
                  </button>
                  </div>
                  </div>
                :
                <div className='export-btn separator'>
                <button
                    disabled='true'
                    className='protondx-btn-primary btn-sm'>Exporting...
                     <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    </button>
                </div>
                }
                {
                    export_data_received && downloadData(exportable_json)
                }
            </div>
            }

            <div className='orglist-card separator'>
                <Table >
                <thead>
                <tr className='th-color'>
                    <th><i className='fas fa-edit'></i></th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Number of test runs</th>
                    <th>Registration date</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                { users && users.map(user=>(
                    user.name && (

                    <tr key={user.auth0_user_id} className={user.permission_group == 'ORG_ADMIN' ? 'tr-disable':null}>
                        {
                            rowId == user.auth0_user_id ?
                        <td>
                            <button
                                className = 'edit-btn'
                                onClick={clearSelectedRow}
                                >
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                        </td>:

                        <td>
                            {
                                user.permission_group == 'ORG_ADMIN' ?
                                <button
                                    className='non-edit-btn'
                                    disabled={true}
                                >
                                    <i class="far fa-square"></i>
                                </button>
                                :
                                <button
                                    className='non-edit-btn'
                                    onClick={()=>{handleRowClick(user.auth0_user_id, user.name, user.surname, user.email)}}
                                >
                                <i class="far fa-square"></i>
                                </button>

                            }
                        </td>
                        }
                        <td>{user.auth0_user_id}</td>
                        <td>{user.name}</td>
                        <td>{user.test_runs}</td>
                        <td>{user.created_at ? user.created_at.toString().replace(/T/, ' ').replace(/\..+/, '') : ""}</td>
                        <td>{user.email}</td>
                    </tr>
                    )
                ))}
                </tbody>
            </Table>
            </div>

             {totalUsers &&
                <div className='paginator'>
                    <Pagination
                        rowsPerPage={usersPerPage}
                        totalRows={totalUsers}
                        paginate={paginate}
                    />
                </div>
            }

             <div className='separator'>
                  {fetching_user_list && <Loading />}
             </div>

        <AddUser
            show={addUserModalShow}
            onHide={() => setAddUserModalShow(false)}
            manageUserList={()=>manageUserList()}
        />

        <EditUser
            user_id ={rowId}
            first_name={editableFirstName}
            surname={editableSurname}
            email={editableEmail}
            show={editUserModalShow}
            unSelectRow={()=>clearSelectedRow()}
            onHide={() => setEditUserModalShow(false)}
            manageUserList={()=>manageUserList()}
        />

        <RemoveUserConfirmation
            user_name={editableFirstName}
            show={removeUserModalShow}
            handleRemove={()=>handleRemove()}
            unSelectRow={()=>clearSelectedRow()}
            onHide={() => setRemoveUserModalShow(false)}
        />
            <div className='separator'>
                <button className='org-manager-btn' onClick={() => setAddUserModalShow(true)}>
                    Add
                </button>

                {rowClicked ?
                <>
                    <button className='org-manager-btn'
                     onClick={handleEdit}>
                     Edit
                    </button>
                    <button className='org-manager-btn'
                        onClick={handleRemoveConfirmation}
                    >
                        Remove
                    </button>
                </>
                    :
                <>
                    <button className='org-manager-btn'  disabled = 'true'>
                        Edit
                    </button>
                    <button className='org-manager-btn'  disabled='true'>
                        Remove
                    </button>
                </>
                }

            </div>
        </div>
    )
}

export default UserList
