import axios from 'axios'
import{ ORG_ADMIN_DATA_EXPORT_FAIL, ORG_ADMIN_DATA_EXPORT_REQUEST, ORG_ADMIN_DATA_EXPORT_SUCCESS, ORG_ADMIN_INFECTION_STATS_FAIL, ORG_ADMIN_INFECTION_STATS_REQUEST, ORG_ADMIN_INFECTION_STATS_SUCCESS, ORG_ADMIN_MANAGE_USER_FAIL, ORG_ADMIN_MANAGE_USER_REQUEST, ORG_ADMIN_MANAGE_USER_SUCCESS, ORG_ADMIN_RESET_CSV_JSON, ORG_ADMIN_STATS_FAIL,
    ORG_ADMIN_STATS_REQUEST,
    ORG_ADMIN_STATS_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS
} from '../types'
import {server_base_url} from '../../shared'


export const getOrgAdminStats = (user_key, query_key) => async (dispatch) =>{
    try {
        dispatch({
            type:ORG_ADMIN_STATS_REQUEST,
        })

        const now = new Date()
        var fromDate
        var toDate = new Date(now.getTime() + (24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
        switch(query_key){
           case 'today':
                fromDate = now.toISOString().slice(0, 10)
                break
            case 'week':
                fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1).toISOString().slice(0, 10)
                break
            case 'month':
                fromDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10)
                break
            case 'year':
                var currentYear = new Date().getFullYear()
                fromDate = `${currentYear}-01-01`
                break
            default:
                fromDate = now.toISOString().slice(0, 10)
        }

        const url = `${server_base_url}/dashboardresults`
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const orgId = JSON.parse(localStorage.getItem('userInfo')).org_id
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }


        const jsondata = {
            from_date: fromDate,
            to_date: toDate,
            org_id: orgId,
            user_id: user_key
        }
        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params: jsondata,
            // data: {}
        })

        const stats_data = data


        const stats = stats_data
        const graphdata = stats_data.graphdata
        const piedata = stats_data.piedata

        const bar_graph_data = {
            labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
              {
                label:"",
                data:[
                  graphdata.january,
                  graphdata.february,
                  graphdata.march,
                  graphdata.april,
                  graphdata.may,
                  graphdata.june,
                  graphdata.july,
                  graphdata.august,
                  graphdata.september,
                  graphdata.october,
                  graphdata.november,
                  graphdata.december
                ],
                backgroundColor: [
                  '#24B34B',
                ],
                borderColor: [
                  '#24B34B',
                ],
                borderWidth: 1,
              },
            ],
          }

        var pie_values = []
        var colorScale = []
        if(piedata.positive > 0) {
            pie_values.push({ x: "Positive", y: piedata.positive })
            colorScale.push("#ff8a8a")
        }
        if(piedata.negative > 0){
            pie_values.push({ x: "Negative", y: piedata.negative })
            colorScale.push("#24b34b")
        }
        if(piedata.noresult > 0){
            pie_values.push({ x: "No Result", y: piedata.noresult })
            colorScale.push("#fec20c")
        }
        // var pie_values = [
        //     { x: "Positive", y: piedata.positive },
        //     { x: "Negative", y: piedata.negative },
        //     { x: "No Result", y: piedata.noresult },
        // ]

        dispatch({
            type:ORG_ADMIN_STATS_SUCCESS,
            stats:stats,
            bar_graph_data:bar_graph_data,
            pie_values:pie_values,
            colorScale
        })

    } catch (error) {
        dispatch({
            type:ORG_ADMIN_STATS_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const getOrgAdminInfectionStats = (user_key, query_key) => async (dispatch, getState) =>{
    try {
        dispatch({
            type:ORG_ADMIN_INFECTION_STATS_REQUEST,
        })

        const now = new Date()
        var fromDate
        var toDate = new Date(now.getTime() + (24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
        switch(query_key){
            case 'today':
                fromDate = now.toISOString().slice(0, 10)
                break
            case 'week':
                fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1).toISOString().slice(0, 10)
                break
            case 'month':
                fromDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10)
                break
            case 'year':
                var currentYear = new Date().getFullYear()
                fromDate = `${currentYear}-01-01`
                break
            default:
                fromDate = now.toISOString().slice(0, 10)
        }
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const orgId = JSON.parse(localStorage.getItem('userInfo')).org_id
        const url = `${server_base_url}/infectiondata`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }


        const jsondata = {
            from_date: fromDate,
            to_date: toDate,
            org_id: orgId,
            user_id: user_key
          }

        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params: jsondata,
            // data: {}
        })
        // percentage calculation
        var total = 0
        if(data.noinfection < 0){
            total = data.covid + data.influenzaA + data.influenzaB + data.rsv + data.rhino
        } else {
            total = data.noinfection + data.covid + data.influenzaA + data.influenzaB + data.rsv + data.rhino
        }

        var covid = 0
        var influenzaA = 0
        var influenzaB = 0
        var rsv = 0
        var rhino =0
        var noinfection =0
         if(total >0){
             covid = ((data.covid / total)*100).toFixed(2)
            influenzaA = ((data.influenzaA / total)*100).toFixed(2)
            influenzaB = ((data.influenzaB / total)*100).toFixed(2)
            rsv = ((data.rsv/ total)*100).toFixed(2)
            rhino = ((data.rhino/ total)*100).toFixed(2)
            noinfection = 100- (Number(covid) + Number(influenzaA) + Number(influenzaB) + Number(rsv) + Number(rhino))
         }

        const infectionData = {
            noinfection,
            covid,
            influenzaA,
            influenzaB,
            rsv,
            rhino
        }

        console.log(infectionData)
        dispatch({
            type:ORG_ADMIN_INFECTION_STATS_SUCCESS,
            payload:infectionData,
        })

    } catch (error) {
        dispatch({
            type:ORG_ADMIN_INFECTION_STATS_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const resetCSVjson = ()=> async (dispatch)=>{

    dispatch({
        type:ORG_ADMIN_RESET_CSV_JSON
    })
}

export const exportOrgAdminData = (fromDate, toDate, userId) => async (dispatch, getState) =>{
    try {
        dispatch({
            type:ORG_ADMIN_DATA_EXPORT_REQUEST,
        })

        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const orgId = JSON.parse(localStorage.getItem('userInfo')).org_id
        const url = `${server_base_url}/export`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }
        const jsondata = {
            from_date: fromDate,
            to_date: toDate,
            user_id:userId,
            org_id:orgId
          }

        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params: jsondata,
            // data: {}
        })

        dispatch({
            type:ORG_ADMIN_DATA_EXPORT_SUCCESS,
            payload:data,
        })

    } catch (error) {
        dispatch({
            type:ORG_ADMIN_DATA_EXPORT_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}


export const getOrgAdminUserList = (current_page, number_of_rows_per_page) => async (dispatch) =>{
    try {
        dispatch({
            type:USER_LIST_REQUEST,
        })

        const orgId = JSON.parse(localStorage.getItem('userInfo')).org_id
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/users`

        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }

        const jsondata = {
            org_id: orgId,
            current_page: current_page,
            number_of_rows_per_page: number_of_rows_per_page
          }

        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params:jsondata,
            // data: {}
        })

        data && data.map(datum =>{
            if(datum.total_rows){
                // Save the user data to the local storage
                localStorage.setItem('pdxTableTotalRows', JSON.stringify(datum.total_rows))
            }
        })

        //Removing number of pages and cols
        data.pop()
        data.pop()

        const user_options = [{
            value:'0', label:'All'
        }]

        const users = data
        users.map(user=>(
            user_options.push({value:user.id, label:user.name})
        ))

        dispatch({
            type:USER_LIST_SUCCESS,
            payload:data,
            options:user_options
        })

    } catch (error) {
        dispatch({
            type:USER_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}
export const addOrgAdminUser = (user_name, user_email) => async (dispatch) =>{
    try {
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_REQUEST
        })

        // TODO: Ask the backend team to remove password field
        const user_password = 'helloworld@12345'

        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const orgId = JSON.parse(localStorage.getItem('userInfo')).org_id
        const url = `${server_base_url}/adduser`

        const jsondata = {
            org_id: orgId,
            name:user_name,
            password:user_password,
            email: user_email
        }

        const {data} = await axios({
            method: 'post',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            data:jsondata,
            // data: {}
        })

        dispatch({
            type:ORG_ADMIN_MANAGE_USER_SUCCESS,
            payload:true
        })

    } catch (error) {
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_FAIL,
            // payload:
            //     error.response && error.response.data.message
            //     ? error.response.data.message
            //     : error.message
        })
    }
}

export const editOrgAdminUser = (auth0_user_id, name, email) => async (dispatch) =>{
    try {
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_REQUEST
        })

        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/edituser`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }

        const {data} = await axios.put(
            url,
            {name,email, auth0_user_id},
            config
        )
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_SUCCESS,
            payload:true
        })

    } catch (error) {
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}


export const removeOrgAdminUser = (user_id) => async (dispatch) =>{
    try {
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_REQUEST,
        })

        const url = `${server_base_url}/deleteuser/${user_id}`

        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop"
            }
        }

        const {data} = await axios.delete(
            url,
            {user_id},
            config
        )

        dispatch({
            type:ORG_ADMIN_MANAGE_USER_SUCCESS,
            payload:true
        })

    } catch (error) {
        dispatch({
            type:ORG_ADMIN_MANAGE_USER_FAIL,
            payload: "Something went wrong."
                // error.response && error.response.data.message
                // ? error.response.data.message
                // : error.message
        })
    }
}
