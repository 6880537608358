import React, {useState, useEffect} from 'react'
import {Modal,Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editOrgAdminUser } from '../../../redux-store/actions/orgAdminActions'


const EditUser = (props) => {

    const [firstName, setFirstName] = useState()
    const [surname, setSurname] = useState()
    const [email, setEmail] = useState()
    const [userId, setUserId] = useState()

    const {processing, success, error} = useSelector((state)=>state.orgAdminManageUser)
    const dispatch = useDispatch()

    const resetState = ()=>{
        setFirstName()
        setSurname()
        setEmail()
    }

    useEffect(() => {
        setUserId(props.user_id)
        setFirstName(props.first_name)
        setEmail(props.email)
    }, [props])
    const editUser = async (event)=>{
        event.preventDefault()
        if(firstName && email){
            await dispatch(editOrgAdminUser(userId, firstName, email))

            // Clear the form
            resetState()
            props.unSelectRow()
            props.onHide()
            props.manageUserList()
            
        }                  
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Edit User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='modal-body'>
                <form>
                    <Row className="mb-3" controlId="firstName">
                        <Col sm='3'><label>Name</label></Col>
                        <Col sm="9">
                            <input 
                                autoFocus
                                type='text'
                                className='modal-input-box'
                                value={firstName}
                                required
                                onChange={(event)=>setFirstName(event.target.value)}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3" controlId="email">
                        <Col sm='3'><label>Email</label> </Col>
                        <Col sm="9">
                            <input 
                                autoFocus
                                type='email'
                                className='modal-input-box'
                                value={email}
                                required
                                onChange={(event)=>setEmail(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <div className='btn-centre'>
                        <button className='protondx-btn-primary btn-lg btn-align' onClick={editUser}>Edit user</button>
                        {/* {error && <Alerts type="danger" message={error} />} */}
                    </div>
                    </form>
                    </div>
                </Modal.Body>
                
            </Modal>
        </div>
    )
}

export default EditUser
