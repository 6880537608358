import React, {useState, useEffect} from 'react'
import {Modal,Row, Col} from 'react-bootstrap'

const RemoveOrgConfirmation = (props) => {

    const [orgName, setOrgName] = useState('')
  
    useEffect(() => {
        setOrgName(props.org_name)
    }, [props])

    const removeOrganization = async (event)=>{
        event.preventDefault()
        if(orgName){
            props.handleRemove()
            // Clear the form
            resetState()
            props.onHide()
        }                  
    }

    const cancelRemoval = ()=>{
            resetState()
            props.unSelectRow()
            props.onHide()
    }
    const resetState = ()=>{
        setOrgName('')
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                
                        Are you sure to remove {orgName}? 
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='modal-body'>
                        <Row className="mb-3" controlId="orgName">
                            <Col sm='6'>
                            <div className='btn-centre'>
                                 <button className='protondx-btn-danger btn-lg btn-align' onClick={removeOrganization}>Remove Organization</button>
                            </div>
                            </Col>
                            <Col sm="6">
                            <div className='btn-centre'>
                                 <button className='protondx-btn-primary btn-lg btn-align' onClick={cancelRemoval}>Cancel</button>
                            </div>
                            </Col>
                        </Row>
                </div>
                    </Modal.Body>
            </Modal>
        </div>
    )
}

export default RemoveOrgConfirmation
