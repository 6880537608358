import React, {useState} from 'react'
import {Form, Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editProtondxAdminOrg } from '../../../redux-store/actions/protondxAdminActions'
import Alerts from '../../partials/Alerts'


const EditOrgDetails = (props) => {

    const [orgName, setOrgName] = useState()
    const [orgRepName, setOrgRepName] = useState()
    const [orgRepEmail, setOrgRepEmail] = useState()

    const {processing, success, error} = useSelector((state)=>state.protondxAdminOrg)
    const dispatch = useDispatch()

    const resetState = ()=>{
        setOrgName()
        setOrgRepName()
        setOrgRepEmail()
    }
    const editOrganizationDetails = ()=>{
        if(orgName && orgRepName && orgRepEmail){
            // TODO: Replace the action
            dispatch(editProtondxAdminOrg(orgName,orgRepName, orgRepEmail))

            // Clear the form
            resetState()
            props.onHide()
        }                  
    }

    return (
        <>
                <Form className='central-screen-org'>
                    <Form.Group as={Row} className="mb-3" controlId="orgName">
                        <Form.Label column sm="3">Organization Name </Form.Label>
                        <Col sm="9">
                            <Form.Control 
                                autoFocus
                                type='text'
                                value={orgName}
                                required
                                onChange={(event)=>setOrgName(event.target.value)}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="orgRepName">
                        <Form.Label column sm="3">Representative Name</Form.Label>
                        <Col sm="9">
                            <Form.Control 
                                autoFocus
                                type='text'
                                value={orgRepName}
                                required
                                onChange={(event)=>setOrgRepName(event.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="3">Representative Email</Form.Label>
                        <Col sm="9">
                            <Form.Control 
                                autoFocus
                                type='email'
                                value={orgRepEmail}
                                required
                                onChange={(event)=>setOrgRepEmail(event.target.value)}
                            />
                        </Col>
                    </Form.Group>
                    <button className='lg-form-btn' onClick={editOrganizationDetails}>Edit organization</button>
                    </Form>
               
                {error && <Alerts type="danger" message={error} />}
        </>
    )
}

export default EditOrgDetails
