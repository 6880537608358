import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getProtondxAdminOrgList } from '../../redux-store/actions/protondxAdminActions'
import { useAuth0 } from '@auth0/auth0-react'

const NavigationBar = (props) => {

    const [orgKey, setOrgKey] = useState('0')
    const history = useHistory()
    const {userInfo} = useSelector(state=>state.logIn)
    const {orgoptions} = useSelector(state=>state.protondxAdminOrgList)

    useEffect(() => {
       dispatch(getProtondxAdminOrgList())
    }, [])

    const handleOrgFilter = (org_id)=>{
        alert(props.handleOrgKey)
    }

    const handleLogout = ()=>{
        localStorage.removeItem('userInfo')
        logout()
    }

    const {logout, user, isLoading,} =useAuth0()

    const dispatch = useDispatch()
    return (
        <div className='protondx-navbar'>
            <div class="topmenu" style={{paddingTop: "1vh"}}>
              <p className='top-logo' style={{pointerEvents: 'none'}}></p>
              {userInfo && (
                <button
                    className='circle'
                    onClick={handleLogout}
                >
                    Logout
                </button>
            )}
            </div>
        </div>
    )
}

export default NavigationBar
