import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const DashboardNavigator = ({btn1, btn2, link1, link2, comp}) => {
    return (
        <div>
            {comp == 'dashboard' ?
            <Row>
                <Col sm='6'>
                    <Link to={link1} className='link'> 
                     <button className='protondx-btn-primary btn-lg'>{btn1}</button>
                    </Link>
                </Col>
                <Col sm='6'>
                    <Link to={link2} className='link'> 
                        <button className='protondx-btn-white btn-lg'>{btn2}</button>
                    </Link>    
                </Col>
            </Row>
            :
            <Row>
            <Col sm='6'>
                <Link to={link1} className='link'> 
                 <button className='protondx-btn-white btn-lg'>{btn1}</button>
                </Link>
            </Col>
            <Col sm='6'>
                <Link to={link2} className='link'> 
                    <button className='protondx-btn-primary btn-lg'>{btn2}</button>
                </Link>    
            </Col>
        </Row>
            }
        </div>
    )
}

export default DashboardNavigator
