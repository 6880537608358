export const server_base_url = process.env.REACT_APP_SERVER_BASE_URL

export const org_fields = {
  "created_date": "Date",
  "created_at": "Time",
  "name" : "User ID",
  "sampleID":"Sample ID",
  "prepKitID" : "Prep Kit REF",
  "lotNo" : "PREP Kit Lot",
  "testerId" : "Test Panel REF",
  "test_panel_ref" : "Test Panel Name",
  "serialNo" : "Test Panel SN",
  "heaterchosen": "Heater ID",
  "controls": "Panel Valid",
  "invalid": "Test Valid",
  "colour_control" : "Control (Colour)",
  "sample_control" : "Control (Sample)",
  "internal_control" : "Control (Internal)",
  "covid": "SARS-CoV-2",
  "influenzaA": "Flu A",
  "influenzaB": "Flu B",
  "rsv": "RSV",
  "rhino":"Rhinovirus",
  "image": "Image",
  "mode": "Mode",
  "totalelapsedtime": "Total elapsed time",
  "lysistime": "Lysis Time (s)",
  "washtime" : "Wash Time (s)",
  "drytime" : "Dry Time (s)",
  "elutetime": "Elute Time (s)",
  "location" : "Location",
  "comments": "Comments",
  "flag": "Flag",
  "prepkitexpired" : "Prep Kit Expired",
  "testpanelexpired" : "Test Panel Expired",
  "heatingexpired" : "<35min incubation",
  "image_captured" : "Image Captured"
}

export const user_fields = {
    "created_date": "Date",
    "created_at": "Time",
    "name" : "User ID",
    "sampleID":"Sample ID",
    "prepKitID" : "Prep Kit REF",
    "lotNo" : "PREP Kit Lot",
    "testerId" : "Test Panel REF",
    "test_panel_ref" : "Test Panel Name",
    "serialNo" : "Test Panel SN",
    "heaterchosen": "Heater ID",
    "controls": "Panel Valid",
    "invalid": "Test Valid",
    "colour_control" : "Control (Colour)",
    "sample_control" : "Control (Sample)",
    "internal_control" : "Control (Internal)",
    "covid": "SARS-CoV-2",
    "influenzaA": "Flu A",
    "influenzaB": "Flu B",
    "rsv": "RSV",
    "rhino":"Rhinovirus",
    "image": "Image",
    "mode": "Mode",
    "totalelapsedtime": "Total elapsed time",
    "lysistime": "Lysis Time (s)",
    "washtime" : "Wash Time (s)",
    "drytime" : "Dry Time (s)",
    "elutetime": "Elute Time (s)",
    "location" : "Location",
    "comments": "Comments",
    "flag": "Flag",
    "prepkitexpired" : "Prep Kit Expired",
    "testpanelexpired" : "Test Panel Expired",
    "heatingexpired" : "<35min incubation",
    "image_captured" : "Image Captured"
}
