import axios from 'axios'
import{ ORG_LIST_FAIL, ORG_LIST_REQUEST, ORG_LIST_SUCCESS, PROTONDX_ADMIN_DATA_EXPORT_FAIL, PROTONDX_ADMIN_DATA_EXPORT_REQUEST, PROTONDX_ADMIN_DATA_EXPORT_SUCCESS, PROTONDX_ADMIN_INFECTION_STATS_FAIL, PROTONDX_ADMIN_INFECTION_STATS_REQUEST, PROTONDX_ADMIN_INFECTION_STATS_SUCCESS, PROTONDX_ADMIN_MANAGE_ORG_FAIL,
    PROTONDX_ADMIN_MANAGE_ORG_REQUEST,
    PROTONDX_ADMIN_MANAGE_ORG_SUCCESS,
    PROTONDX_ADMIN_RESET_CSV_JSON,
    PROTONDX_ADMIN_STATS_FAIL,
    PROTONDX_ADMIN_STATS_REQUEST,
    PROTONDX_ADMIN_STATS_SUCCESS,
} from '../types'
import {server_base_url} from '../../shared'

export const getProtondxAdminStats = (org_key, query_key) => async (dispatch, getState) =>{
    try {
        dispatch({
            type:PROTONDX_ADMIN_STATS_REQUEST,
        })

        const now = new Date()
        var fromDate
        var toDate = new Date(now.getTime() + (24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
        switch(query_key){
           case 'today':
                fromDate = now.toISOString().slice(0, 10)
                break
            case 'week':
                fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1).toISOString().slice(0, 10)
                break
            case 'month':
                fromDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10)
                break
            case 'year':
                var currentYear = new Date().getFullYear()
                fromDate = `${currentYear}-01-01`
                break
            default:
                fromDate = now.toISOString().slice(0, 10)
        }

        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/dashboardresults`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }

        var jsondata = {}
        if(org_key === '0'){
            jsondata = {
                from_date: fromDate,
                to_date: toDate,
              }
        }else{
            jsondata = {
            from_date: fromDate,
            to_date: toDate,
            org_id: org_key,
          }
        }


        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params: jsondata,
            // data: {}
        })


        const stats_data = data

        const stats = stats_data
        const graphdata = stats_data.graphdata
        const piedata = stats_data.piedata


        const bar_graph_data = {
            labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
              {
                label:"",
                data:[
                  graphdata.january,
                  graphdata.february,
                  graphdata.march,
                  graphdata.april,
                  graphdata.may,
                  graphdata.june,
                  graphdata.july,
                  graphdata.august,
                  graphdata.september,
                  graphdata.october,
                  graphdata.november,
                  graphdata.december
                ],
                backgroundColor: [
                  '#24B34B',
                ],
                borderColor: [
                  '#24B34B',
                ],
                borderWidth: 1,
              },
            ],
          }

        //  const pie_values = [
        //     { x: "Positive", y: piedata.positive },
        //     { x: "Negative", y: piedata.negative },
        //     { x: "No Result", y: piedata.noresult },
        //   ]
        var pie_values = []
        var colorScale = []
        if(piedata.positive > 0) {
            pie_values.push({ x: "Positive", y: piedata.positive })
            colorScale.push("#ff8a8a")
        }
        if(piedata.negative > 0){
            pie_values.push({ x: "Negative", y: piedata.negative })
            colorScale.push("#24b34b")
        }
        if(piedata.noresult > 0){
            pie_values.push({ x: "No Result", y: piedata.noresult })
            colorScale.push("#fec20c")
        }

        dispatch({
            type:PROTONDX_ADMIN_STATS_SUCCESS,
            stats:stats,
            bar_graph_data:bar_graph_data,
            pie_values:pie_values,
            colorScale
        })

    } catch (error) {
        dispatch({
            type:PROTONDX_ADMIN_STATS_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const getProtondxAdminInfectionStats = (org_key, query_key) => async (dispatch, getState) =>{
    try {
        dispatch({
            type:PROTONDX_ADMIN_INFECTION_STATS_REQUEST,
        })

        const now = new Date()
        var fromDate
        var toDate = new Date(now.getTime() + (24 * 60 * 60 * 1000)).toISOString().slice(0, 10)
        switch(query_key){
           case 'today':
                fromDate = now.toISOString().slice(0, 10)
                break
            case 'week':
                fromDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1).toISOString().slice(0, 10)
                break
            case 'month':
                fromDate = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10)
                break
            case 'year':
                var currentYear = new Date().getFullYear()
                fromDate = `${currentYear}-01-01`
                break
            default:
                fromDate = now.toISOString().slice(0, 10)
        }
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/infectiondata`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }
        var jsondata = {}
        if(org_key === '0'){
            jsondata = {
                from_date: fromDate,
                to_date: toDate,
              }
        }else{
            jsondata = {
            from_date: fromDate,
            to_date: toDate,
            org_id: org_key,
          }
        }

        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params: jsondata,
            // data: {}
        })


        // percentage calculation
        var total = 0
        if(data.noinfection < 0){
            total = data.covid + data.influenzaA + data.influenzaB + data.rsv + data.rhino
        } else {
            total = data.noinfection + data.covid + data.influenzaA + data.influenzaB + data.rsv + data.rhino
        }

        var covid = 0
        var influenzaA = 0
        var influenzaB = 0
        var rsv = 0
        var rhino =0
        var noinfection =0
         if(total >0){
             covid = ((data.covid / total)*100).toFixed(2)
            influenzaA = ((data.influenzaA / total)*100).toFixed(2)
            influenzaB = ((data.influenzaB / total)*100).toFixed(2)
            rsv = ((data.rsv/ total)*100).toFixed(2)
            rhino = ((data.rhino/ total)*100).toFixed(2)
            noinfection = 100- (Number(covid) + Number(influenzaA) + Number(influenzaB) + Number(rsv) + Number(rhino))
         }
        const infectionData = {
            noinfection,
            covid,
            influenzaA,
            influenzaB,
            rsv,
            rhino
        }

        dispatch({
            type:PROTONDX_ADMIN_INFECTION_STATS_SUCCESS,
            payload:infectionData,
        })

    } catch (error) {
        dispatch({
            type:PROTONDX_ADMIN_INFECTION_STATS_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}


export const getProtondxAdminOrgList = (current_page, number_of_rows_per_page) => async (dispatch, getState) =>{
    try {
        dispatch({
            type:ORG_LIST_REQUEST,
        })

        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/organizations`
        const config = {
            headers:{
                'Content-Type':'application/json',
                'X-AuthorityToken': 'auth-postman',
                "X-AccessToken" : accessToken
            }
        }

        const jsondata = {
            current_page,
            number_of_rows_per_page
          }
        // const {data} = await axios.get(
        //     url,
        //     config,
        // )

         const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params:jsondata,
            // data: {}
        })

        data && data.map(datum =>{
            if(datum.total_rows){
                // Save the user data to the local storage
                localStorage.setItem('pdxTableTotalRows', JSON.stringify(datum.total_rows))
            }
        })

        data.pop()
        data.pop()

        const org_options = [{
            value:'', label:'All'
        }]

        const orgs = data
        orgs && orgs.map(org=>(
            org_options.push({value:org.id, label:org.name})

        ))
        dispatch({
            type:ORG_LIST_SUCCESS,
            payload:data,
            options:org_options
        })

    } catch (error) {
        dispatch({
            type:ORG_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const resetCSVjson = ()=> async (dispatch)=>{

    dispatch({
        type:PROTONDX_ADMIN_RESET_CSV_JSON
    })
}

export const exportProtondxAdminData = (fromDate, toDate, orgId) => async (dispatch, getState) =>{
    try {
        dispatch({
            type:PROTONDX_ADMIN_DATA_EXPORT_REQUEST,
        })

        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/export`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }

        const jsondata = {
            from_date: fromDate,
            to_date: toDate,
            org_id:orgId,
          }

        const {data} = await axios({
            method: 'get',
            url: url,
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            },
            params: jsondata,
            // data: {}
        })

       const dataLength = data.length
        dispatch({
            type:PROTONDX_ADMIN_DATA_EXPORT_SUCCESS,
            payload:data,
            data_received:true
        })

    } catch (error) {
        dispatch({
            type:PROTONDX_ADMIN_DATA_EXPORT_FAIL,
            payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const addProtondxAdminOrg = (orgName, orgRepName, orgRepEmail) => async (dispatch, getState) =>{
    const name = orgName
    const representative = orgRepName
    const email = orgRepEmail



    try {
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_REQUEST
        })

        const {logIn:{userInfo}} = getState()
        const accessToken = JSON.parse(localStorage.getItem('userInfo')).auth0_access_token
        const url = `${server_base_url}/addorganization`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop",
                "X-AccessToken" : accessToken
            }
        }

        const {data} = await axios.post(
            url,
            {name, email,representative},
            config
        )
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_SUCCESS,
            payload:true
        })

    } catch (error) {
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_FAIL,
            payload:
                    // "Something went wrong."
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        })
    }
}

export const editProtondxAdminOrg = (org_id, name, representative, email) => async (dispatch) =>{
    try {
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_REQUEST,
        })


        const url = `${server_base_url}/organization/${org_id}`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop"
            }
        }

        const {data} = await axios.put(
            url,
            {name, representative, email},
            config
        )

        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_SUCCESS,
            payload:true
        })

    } catch (error) {
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_FAIL,
            payload: "Something went wrong."
                // error.response && error.response.data.message
                // ? error.response.data.message
                // : error.message
        })
    }
}

export const removeProtondxAdminOrg = (org_id) => async (dispatch) =>{
    try {
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_REQUEST,
        })

        const url = `${server_base_url}/deleteorganization/${org_id}`
        const config = {
            headers:{
                'Content-Type':'application/json',
                "X-AuthorityToken": "auth-wpf-desktop"
            }
        }

        const {data} = await axios.delete(
            url,
            {org_id},
            config
        )

        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_SUCCESS,
            payload:true
        })

    } catch (error) {
        dispatch({
            type:PROTONDX_ADMIN_MANAGE_ORG_FAIL,
        })
    }
}
