import React, {useState, useEffect} from 'react'
import {Table, Button, InputGroup} from 'react-bootstrap'
import DashboardNavigator from '../../partials/DashboardNavigator'
import NavigationBar from '../../partials/NavigationBar'
import AddOrg from './AddOrg'
import EditOrg from './EditOrg'
import { Row,Col } from 'react-bootstrap'
import { exportProtondxAdminData, getProtondxAdminOrgList, removeProtondxAdminOrg, resetCSVjson } from '../../../redux-store/actions/protondxAdminActions'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { org_fields } from '../../../shared'
import { Redirect } from 'react-router'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import RemoveOrgConfirmation from './RemoveOrgConfirmation'
import Loading from '../../partials/Loading'
import Pagination from '../../partials/Pagination'

import {
    useJsonToCsv
  } from 'react-json-csv'

const OrgList = () => {

    const [addOrgModalShow, setAddOrgModalShow] = useState(false)
    const [editOrgModalShow, setEditOrgModalShow] = useState(false)
    const [removeOrgModalShow, setRemoveOrgModalShow] = useState(false)
    const [rowClicked, setRowClicked] = useState(false)
    const [rowId, setRowId] = useState('')
    const [editableOrgName, setEditableOrgName]= useState('')
    const [editableOrgRep, setEditableOrgRep]= useState('')
    const [editableOrgRepEmail, setEditableOrgRepEmail]= useState('')
    const [manageOrg, setManageOrg]=useState(0)
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [orgExportId, setOrgExportId] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [orgsPerPage] = useState(10)
    const [errorMsg, setErrorMsg] = useState('')

    const {orgs, orgoptions, fetching_org_list} = useSelector((state)=>state.protondxAdminOrgList)
    const {exportable_json, exporting, export_data_received} = useSelector((state)=>state.protondxAdminExportData)

    const dispatch = useDispatch()

    useEffect(() => {
        async function getOrgList(){
            await dispatch(getProtondxAdminOrgList(currentPage, orgsPerPage))
        }
        getOrgList()
    }, [manageOrg, currentPage])


    const handleRemoveConfirmation = ()=>{
        setRemoveOrgModalShow(true)
    }

    const handleRemove = ()=>{
        dispatch(removeProtondxAdminOrg(rowId))
        clearSelectedRow()
        setManageOrg(Math.random())
    }

    const handleEdit = ()=>{
        setEditOrgModalShow(true)
        setManageOrg(Math.random())
    }

    const manageOrganization = ()=>{
        setManageOrg(Math.random())
    }
    const handleRowClick = (row_id, org_name, org_rep, org_email) =>{
        setRowId(row_id)
        setEditableOrgName(org_name)
        setEditableOrgRep(org_rep)
        setEditableOrgRepEmail(org_email)
        setRowClicked(true)
    }

    const clearSelectedRow = ()=>{
        setRowClicked(false)
        setRowId(false)
        setEditableOrgName('')
        setEditableOrgRep('')
        setEditableOrgRepEmail('')
    }

    const { saveAsCsv } = useJsonToCsv()

    const filename = 'exported-result'

    const exportData = async ()=>{

        if(toDate == null && typeof(toDate) == "undefined"){
          setErrorMsg("Please select a time period.")
          return
        }
        if(fromDate == null && typeof(fromDate) == "undefined"){
          setErrorMsg("Please select a time period.")
          return
        }
        if(toDate.length <= 0){
          setErrorMsg("Please select a time period.")
          return
        }
        if(fromDate.length <= 0){
          setErrorMsg("Please select a time period.")
          return
        }
        setErrorMsg("")
        await dispatch(exportProtondxAdminData(moment(fromDate).format("YYYY-MM-DD"),
          moment(toDate).format("YYYY-MM-DD"),
          orgExportId))
    }

    const downloadData = (data)=>{
        const fields = org_fields
        if(data && data.length!==0) {

            saveAsCsv({ data, fields, filename })
        }
        else alert("No results")

        dispatch(resetCSVjson())
    }

         // Change page
    const paginate = pageNumber => {
            setCurrentPage(pageNumber)
    }

    const handleToDateChange = date => {
      setErrorMsg("")
      setToDate(date)
    }

    const handleFromDateChange = date => {
      setErrorMsg("")
      setFromDate(date)
    }

    // Get current orgs page
    const totalOrgs = JSON.parse(localStorage.getItem('pdxTableTotalRows'))

     return (
        <div>
            {!localStorage.getItem('userInfo') && <Redirect to='/login' />}
            {console.log(orgs)}

            <NavigationBar />

            <DashboardNavigator
                btn1={'ProtonDx Dashboard'}
                link1={'/protondx-admin/protondx-dashboard'}
                btn2={'Organization List'}
                link2={'/protondx-admin/orglist'}
                comp={'orglist'}
             />

             {orgoptions &&

            <div className='orglist-card separator'>


                <Row>
                <p className="dataexport">Data Export</p>
                    <Col sm='1'></Col>
                    <Col sm='3'>
                        <div className='export-label'>From</div>
                             <DatePicker selected={fromDate} onChange={handleFromDateChange}
                                dateFormat="yyyy-MM-dd"
                             />
                    </Col>
                    <Col sm='3'>
                            <div className='export-label'>To</div>
                            <DatePicker
                                selected={toDate}
                                onChange={handleToDateChange}
                                dateFormat="yyyy-MM-dd"
                            />
                    </Col>
                    <Col sm='3'>
                        <div className='export-label'>Organization</div>
                        <select
                          className='select-dark'
                          value={orgExportId}
                          style={{padding: "8px"}}
                          onChange={e => setOrgExportId(e.target.value)}>
                          {orgoptions.map(option => (
                            <option key={option.value} value={option.value}
                            >{option.label}</option>
                          ))}
                        </select>
                    </Col>
                </Row>
                {!exporting?
                <div>
                <div className="export-error">{errorMsg}</div>
                <div className='export-btn separator'>
                <button
                    onClick={exportData}
                    className='protondx-btn-primary btn-sm'>Export Results
                </button>
                </div>
                </div>
                :
                <div className='export-btn separator'>
                <button
                    disabled='true'
                    className='protondx-btn-primary btn-sm'>Exporting...
                     <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    </button>
                </div>
                }
                {
                    export_data_received && downloadData(exportable_json)
                }
            </div>
             }

            <div className='orglist-card separator'>
                <Table >
                <thead>
                <tr className='th-color'>
                    <th><i className='fas fa-edit'></i></th>
                    <th>Organization name</th>
                    <th>Number of users</th>
                    <th>Number of test runs</th>
                    {/* <th>Registration date</th> */}
                    <th>Representative</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                { orgs && orgs.map(org=>(
                    org.name && (

                    <tr key={org.id} className={org.permission_group == 'PROTON_DX_ADMIN' ? 'tr-disable':null}>
                        {
                            rowId == org.id ?
                        <td>
                            <button
                                className = 'edit-btn'
                                onClick={clearSelectedRow}
                                >
                                <i class="fa fa-check" ></i>
                            </button>
                        </td>:

                        <td>
                            {
                                org.permission_group == 'PROTON_DX_ADMIN'?

                                <button
                                    className='non-edit-btn'
                                    disabled={true}
                                >
                                <i class="far fa-square"></i>
                                </button>

                                :
                                <button
                                    className='non-edit-btn'
                                    onClick={()=>{handleRowClick(org.id, org.name, org.representative, org.email)}}
                                >
                                <i class="far fa-square"></i>
                                </button>

                            }
                        </td>
                        }
                        <td>{org.name}</td>
                        <td>{org.no_of_users}</td>
                        <td>{org.no_of_test_runs}</td>
                        {/* <td>{org.registration_date}</td> */}
                        <td>{org.representative}</td>
                        <td>{org.email}</td>
                    </tr>
                    )
                ))}
                </tbody>
            </Table>
            </div>

            {totalOrgs &&
                <div className='paginator'>
                    <Pagination
                        rowsPerPage={orgsPerPage}
                        totalRows={totalOrgs}
                        paginate={paginate}
                    />
                </div>
            }

             <div className='separator'>
                  {fetching_org_list && <Loading />}
             </div>
        <AddOrg
            show={addOrgModalShow}
            onHide={() => setAddOrgModalShow(false)}
            manageOrganization={()=>manageOrganization()}
        />

        <EditOrg
            org_id ={rowId}
            org_name={editableOrgName}
            org_rep={editableOrgRep}
            org_email={editableOrgRepEmail}
            show={editOrgModalShow}
            unSelectRow={()=>clearSelectedRow()}
            onHide={() => setEditOrgModalShow(false)}
            manageOrganization={()=>manageOrganization()}
        />
        <RemoveOrgConfirmation
            org_name={editableOrgName}
            show={removeOrgModalShow}
            handleRemove={()=>handleRemove()}
            unSelectRow={()=>clearSelectedRow()}
            onHide={() => setRemoveOrgModalShow(false)}
        />

            <div className='separator'>
                <button className='org-manager-btn' onClick={() => setAddOrgModalShow(true)}>
                    Add
                </button>

                {rowClicked ?
                <>
                    <button className='org-manager-btn'
                     onClick={handleEdit}>
                     Edit
                    </button>
                    <button className='org-manager-btn'
                        onClick={handleRemoveConfirmation}>
                        Remove
                    </button>
                </>
                    :
                <>
                    <button className='org-manager-btn'  disabled = 'true'>
                        Edit
                    </button>
                    <button className='org-manager-btn'  disabled='true'>
                        Remove
                    </button>
                </>
                }

            </div>
        </div>
    )
}

export default OrgList
