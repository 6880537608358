import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Container} from 'react-bootstrap'
import Login from './components/authentication/Login'
import OrgList from './components/dashboard-manager/protondx-admin/OrgList'
import UserList from './components/dashboard-manager/org-admin/UserList'
import ProtondxDashboard from './components/dashboard-manager/protondx-admin/ProtondxDashboard'
import OrgDashboard from './components/dashboard-manager/org-admin/OrgDashboard';
import EditOrgDetails from './components/dashboard-manager/org-admin/EditOrgDetails';
import Auth0ProviderWithHistory  from './components/auth0/Auth0ProviderWithHistory'
import Auth0DashBridge from './components/auth0/Auth0DashBridge';
import Authorization from './components/authentication/Authorization';
function App() {
  return (
    <>
     
    <Router>
      <Auth0ProviderWithHistory>
          <Container> 
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path = '/protondx-admin/orglist' component= {OrgList} />
              <Route exact path = '/protondx-admin/protondx-dashboard' component= {ProtondxDashboard} />
              <Route exact path = '/org-admin/userlist' component = {UserList} />
              <Route exact path = '/org-admin/org-dashboard' component = {OrgDashboard} />
              <Route exact path = '/org-admin/edit-org-details' component = {EditOrgDetails} />
              <Route exact path= '/callback/' component={Auth0DashBridge} />
              <Route exact path= '/' component={Authorization} />
              <Route exact path= '/logout' component={Authorization} />
            </Switch>
          </Container>
      </Auth0ProviderWithHistory>    
    </Router>
   </> 
  );
}

export default App;
