import React, {useEffect, useState} from 'react'
import {Bar} from 'react-chartjs-2'
import DashboardPie from '../../partials/DashboardPie'
import { useDispatch, useSelector } from 'react-redux'
import {getProtondxAdminStats, getProtondxAdminInfectionStats, getProtondxAdminOrgList} from '../../../redux-store/actions/protondxAdminActions'
import Alerts from '../../partials/Alerts'
import { Row, Col } from 'react-bootstrap'
import NavigationBar from '../../partials/NavigationBar'
import DashboardNavigator from '../../partials/DashboardNavigator'
import { VictoryPie } from "victory-pie"
import { Redirect } from 'react-router'
import Loading from '../../partials/Loading'



  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
          display: false
      }
  }
  };
const ProtondxDashboard = () => {

  const dataFilterKeys = [
    {value:'today', label:'Today'},
    {value:'week', label:'Week'},
    {value:'month', label:'Month'},
    {value:'year', label:'Year'}
  ]

  const [queryKey, setQueryKey] = useState('today')
  const [orgKey, setOrgKey] = useState('0')
  const [currentDayYear, setCurrentDayYear] = useState(0)
  const [currentDayMonth, setCurrentDayMonth] = useState(0)

  const {stats, barGraphData, pievalues, colorScale} = useSelector((state)=>state.protondxAdminStats)
  const {orgoptions} = useSelector((state)=>state.protondxAdminOrgList)

  const {infectionStats} = useSelector((state)=>state.protondxAdminInfectionStats)


  const dispatch = useDispatch()

  const handleDataFilter = (query_key)=>{
    setQueryKey(query_key)

  }
  const handleOrgFilter = (org_id)=>{
    setOrgKey(org_id)
  }


  const currentDay = () => {
    var date = new Date()
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000
  }

  const currentMonth = () => {
    var date = new Date()
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), date.getMonth(), 0)) / 24 / 60 / 60 / 1000
  }
  useEffect(() => {
    setCurrentDayYear(currentDay())
    setCurrentDayMonth(currentMonth())
   dispatch(getProtondxAdminStats(orgKey, queryKey))
   dispatch(getProtondxAdminInfectionStats(orgKey, queryKey))
  }, [dispatch, orgKey])

  useEffect(() => {
      setCurrentDayYear(currentDay())
      setCurrentDayMonth(currentMonth())
    dispatch(getProtondxAdminStats(orgKey, queryKey))
    dispatch(getProtondxAdminInfectionStats(orgKey, queryKey))
   }, [dispatch, queryKey])

  useEffect(()=>{
      setCurrentDayYear(currentDay())
      setCurrentDayMonth(currentMonth())
    dispatch(getProtondxAdminOrgList(orgKey, queryKey))
  }, [])

    return (
        <div>

          {!localStorage.getItem('userInfo') && <Redirect to='/login' />}
             <NavigationBar />
             <div class="topmenu">
             {orgoptions &&
              <select
                className='circle'
                value={orgKey}
                style={{padding: "8px", marginRight:"20px"}}
                onChange={e => handleOrgFilter(e.target.value)}>
                {orgoptions.map(option => (
                <option key={option.value} value={option.value}
                >{option.label}</option>
                ))}
            </select>}
            </div>

             <DashboardNavigator
                btn1={'ProtonDx Dashboard'}
                link1={'/protondx-admin/protondx-dashboard'}
                btn2={'Organization List'}
                link2={'/protondx-admin/orglist'}
                comp={'dashboard'}
             />
             <div className='separator'>
                  {!stats && <Loading />}
             </div>

             {stats &&
             <div className='dashboard-content'>
             <Row>
               {/* Bar Stats*/}
               <Col sm='7'>
                  <div className='dashboard-card'>
                   <Row>
                      <Col sm='6'>
                          <label className='bar-stats-heading'>
                            Statistics
                          </label>
                          <label className='bar-stats'>
                            {stats.testtoday} tests today
                          </label>
                          <label className='bar-stats'>
                            {stats.testmonth} tests last 28 days
                          </label>
                          <label className='bar-stats'>
                            {stats.testyear} tests last 365 days
                          </label>
                      </Col>
                      <Col sm='6'>
                      <div className='piegraph'>
                          <VictoryPie
                            data={pievalues}
                            colorScale={colorScale}
                            radius={100}
                          />
                      </div>
                      </Col>
                    </Row>
                    <div className='bargraph'>
                          <Bar data={barGraphData} options={options} />
                    </div>
                  </div>

               </Col>

               {/* Pie  Stats*/}
               <Col sm='5'>
                <div className='dashboard-card'>
                    <Row>
                      <Row className="aligncenter">
                        <Col sm='4'>
                          <label>Statistics From </label>
                        </Col>
                        <Col sm='4'>
                        <select
                          value={queryKey}
                          style={{padding: '9px'}}
                          onChange={e => handleDataFilter(e.target.value)}>
                          {dataFilterKeys.map(option => (
                            <option key={option.value} value={option.value} style={{padding: '5px'}}
                            >{option.label}</option>
                          ))}
                        </select>
                        </Col>
                      </Row>
                      <Col lg='4'>
                      <div className='pie-card-container'>
                        <label className='pie-label'>No Infection </label>
                        <div className='pie-card no-infection'>
                            <DashboardPie percentage={infectionStats ? Math.round(infectionStats.noinfection) : 0} progressColor={'rgba(90, 134, 181, 1)'} trailColor={'rgba(158, 197, 238, 0.2)'}/>
                        </div>
                      </div>
                      </Col>
                      <Col lg='4'>
                        <div className='pie-card-container'>
                          <label className='pie-label'>SARS-CoV-2  </label>
                          <div className='pie-card covid-19'>
                            <DashboardPie percentage={infectionStats ? Math.round(infectionStats.covid) : 0} progressColor={'rgba(102, 228, 127, 1)'} trailColor={'rgba(102, 228, 127, 0.2)'}/>
                          </div>
                        </div>
                      </Col>
                      <Col lg='4'>
                        <div className='pie-card-container'>
                           <label className='pie-label'>Flu A </label>
                          <div className='pie-card flu-a'>
                            <DashboardPie percentage={infectionStats ? Math.round(infectionStats.influenzaA) : 0} progressColor={'rgba(155, 155, 255, 1)'} trailColor={'rgba(158, 197, 238, 0.2)'} />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg='4'>
                        <div className='pie-card-container'>
                        <label className='pie-label'>Flu B </label>
                            <div className='pie-card flu-b'>

                              <DashboardPie percentage={infectionStats ? Math.round(infectionStats.influenzaB) : 0} progressColor={'rgba(255, 164, 119, 1)'} trailColor={'rgba(255, 164, 119, 0.2)'}/>
                            </div>
                        </div>
                      </Col>
                      <Col lg='4'>
                        <div className='pie-card-container'>
                        <label className='pie-label'>RSV </label>
                            <div className='pie-card rsv'>

                                <DashboardPie percentage={infectionStats ? Math.round(infectionStats.rsv) : 0} progressColor={'rgba(72, 143, 240, 1)'} trailColor={'rgba(158, 197, 238, 0.2)'}/>
                            </div>
                        </div>
                      </Col>
                      <Col lg='4'>
                        <div className='pie-card-container'>
                        <label className='pie-label'>HRV </label>
                            <div className='pie-card hrv'>

                                <DashboardPie percentage={infectionStats ? Math.round(infectionStats.rhino) : 0} progressColor={'rgba(72, 194, 226, 1)'} trailColor={'rgba(158, 197, 238, 0.2)'} />
                            </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
               </Col>
             </Row>
            </div>
}
        </div>
    )
}

export default ProtondxDashboard
