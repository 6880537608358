/*_____Login_____*/ 
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'SIGNOUT'

export const AUTH0_LOGIN_REQUEST = 'AUTH0_LOGIN_REQUEST'
export const AUTH0_LOGIN_SUCCESS = 'AUTH0_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL'
export const AUTH0_LOGOUT = 'AUTH0_LOGOUT'

/*_________Protondx Admin_________*/
export const ORG_LIST_REQUEST = 'ORG_LIST_REQUEST'
export const ORG_LIST_SUCCESS = 'ORG_LIST_SUCCESS'
export const ORG_LIST_FAIL = 'ORG_LIST_FAIL'

export const PROTONDX_ADMIN_STATS_REQUEST = 'PROTONDX_ADMIN_STATS_REQUEST'
export const PROTONDX_ADMIN_STATS_SUCCESS = 'PROTONDX_ADMIN_STATS_SUCCESS'
export const PROTONDX_ADMIN_STATS_FAIL = 'PROTONDX_ADMIN_STATS_FAIL'

export const PROTONDX_ADMIN_INFECTION_STATS_REQUEST = 'PROTONDX_ADMIN_INFECTION_STATS_REQUEST'
export const PROTONDX_ADMIN_INFECTION_STATS_SUCCESS = 'PROTONDX_ADMIN_INFECTION_STATS_SUCCESS'
export const PROTONDX_ADMIN_INFECTION_STATS_FAIL = 'PROTONDX_ADMIN_INFECTION_STATS_FAIL'

export const PROTONDX_ADMIN_MANAGE_ORG_REQUEST = 'PROTONDX_ADMIN_MANAGE_ORG_REQUEST'
export const PROTONDX_ADMIN_MANAGE_ORG_SUCCESS = 'PROTONDX_ADMIN_MANAGE_ORG_SUCCESS'
export const PROTONDX_ADMIN_MANAGE_ORG_FAIL = 'PROTONDX_ADMIN_MANAGE_ORG_FAIL'
export const PROTONDX_ADMIN_MANAGE_ORG_RESET = 'PROTONDX_ADMIN_MANAGE_ORG_RESET'


export const PROTONDX_ADMIN_DATA_EXPORT_REQUEST = 'PROTONDX_ADMIN_DATA_EXPORT_REQUEST'
export const PROTONDX_ADMIN_DATA_EXPORT_SUCCESS = 'PROTONDX_ADMIN_DATA_EXPORT_SUCCESS'
export const PROTONDX_ADMIN_DATA_EXPORT_FAIL = 'PROTONDX_ADMIN_DATA_EXPORT_FAIL'


export const PROTONDX_ADMIN_RESET_CSV_JSON = 'PROTONDX_ADMIN_RESET_CSV_JSON' 
/*_________Organization Admin_____*/
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'

export const ORG_ADMIN_STATS_REQUEST = 'ORG_ADMIN_STATS_REQUEST'
export const ORG_ADMIN_STATS_SUCCESS = 'ORG_ADMIN_STATS_SUCCESS'
export const ORG_ADMIN_STATS_FAIL = 'ORG_ADMIN_STATS_FAIL'

export const ORG_ADMIN_MANAGE_USER_REQUEST = 'ORG_ADMIN_MANAGE_USER_REQUEST'
export const ORG_ADMIN_MANAGE_USER_SUCCESS = 'ORG_ADMIN_MANAGE_USER_SUCCESS'
export const ORG_ADMIN_MANAGE_USER_FAIL = 'ORG_ADMIN_MANAGE_USER_FAIL'
export const ORG_ADMIN_MANAGE_USER_RESET = 'ORG_ADMIN_MANAGE_USER_RESET'


export const ORG_ADMIN_INFECTION_STATS_REQUEST = 'ORG_ADMIN_INFECTION_STATS_REQUEST'
export const ORG_ADMIN_INFECTION_STATS_SUCCESS = 'ORG_ADMIN_INFECTION_STATS_SUCCESS'
export const ORG_ADMIN_INFECTION_STATS_FAIL = 'ORG_ADMIN_INFECTION_STATS_FAIL'

export const ORG_ADMIN_DATA_EXPORT_REQUEST = 'ORG_ADMIN_DATA_EXPORT_REQUEST'
export const ORG_ADMIN_DATA_EXPORT_SUCCESS = 'ORG_ADMIN_DATA_EXPORT_SUCCESS'
export const ORG_ADMIN_DATA_EXPORT_FAIL = 'ORG_ADMIN_DATA_EXPORT_FAIL'

export const ORG_ADMIN_RESET_CSV_JSON = 'ORG_ADMIN_RESET_CSV_JSON' 

