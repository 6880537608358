import React, {useState, useEffect} from 'react'
import {Modal,Form, Row, Col, ModalBody} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { editProtondxAdminOrg } from '../../../redux-store/actions/protondxAdminActions'
import Alerts from '../../partials/Alerts'

const EditOrg = (props) => {

    const [orgName, setOrgName] = useState('')
    const [orgRepName, setOrgRepName] = useState('')
    const [orgRepEmail, setOrgRepEmail] = useState('')
    const [orgId, setOrgId] = useState('')

    const {processing, success, error} = useSelector((state)=>state.protondxAdminManageOrg)
    const dispatch = useDispatch()

    useEffect(() => {
        setOrgId(props.org_id)
        setOrgName(props.org_name)
        setOrgRepEmail(props.org_email)
        setOrgRepName(props.org_rep)
    }, [props])

    const editOrganization = async (event)=>{
        event.preventDefault()
        if(orgName && orgRepName && orgRepEmail){
            await dispatch(editProtondxAdminOrg(orgId, orgName,orgRepName, orgRepEmail))

            // Clear the form
            resetState()
            props.unSelectRow()
            props.onHide()
            props.manageOrganization()
        }                  
    }
    const resetState = ()=>{
        setOrgName('')
        setOrgRepEmail('')
        setOrgRepName('')
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Edit Organization
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='modal-body'>
                    <form>
                        <Row className="mb-3" controlId="orgName">
                            <Col sm='3'>
                                <label >Organization Name </label>
                            </Col>
                            <Col sm="9">
                                <input 
                                    autoFocus
                                    type='text'
                                    value={orgName}
                                    required
                                    onChange={(event)=>setOrgName(event.target.value)}
                                    className='modal-input-box'
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3" controlId="orgRepName">
                            <Col sm='3'>
                                <label column sm="3">Representative Name</label>
                            </Col>    
                            <Col sm="9">
                                <input 
                                    autoFocus
                                    type='text'
                                    value={orgRepName}
                                    required
                                    onChange={(event)=>setOrgRepName(event.target.value)}
                                    className='modal-input-box'
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3" controlId="formPlaintextPassword">
                            <Col sm='3'>
                            <label column sm="3">Representative Email</label>
                            </Col> 
                            <Col sm="9">
                                <input 
                                    autoFocus
                                    type='email'
                                    value={orgRepEmail}
                                    required
                                    onChange={(event)=>setOrgRepEmail(event.target.value)}
                                    className='modal-input-box'
                                />
                            </Col>
                        </Row>
                        <div className='btn-centre'>
                            <button className='protondx-btn-primary btn-lg btn-align' onClick={editOrganization}>Edit Organization</button>
                        </div>
                        {/* {error && <Alerts type="danger" message={error} />} */}
                        {/* {success && <Alerts type="success" message={'Organization edited.'} />} */}
                        </form>
                       
                        </div>
                    </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditOrg
