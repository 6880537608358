import React, {useEffect, useState} from 'react'
import {Modal,Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addOrgAdminUser } from '../../../redux-store/actions/orgAdminActions'
import { ORG_ADMIN_MANAGE_USER_RESET } from '../../../redux-store/types'
import Alerts from '../../partials/Alerts'
import { Spinner } from 'react-bootstrap'

const AddUser = (props) => {

    const [firstName, setFirstName] = useState()
    // const [password, setPassword] = useState()
    const [email, setEmail] = useState()

    const {processing, success, error} = useSelector((state)=>state.orgAdminManageUser)
    const dispatch = useDispatch()

    const resetState = ()=>{
        setFirstName()
        // setPassword()
        setEmail()
    }
    const addUser = async (e)=>{
        e.preventDefault()
        if(firstName && email){
            await dispatch(addOrgAdminUser(firstName, email))

        }                  
    }

    useEffect(()=>{
        if(success){
            resetState()
            props.manageUserList()
            props.onHide()
            dispatch({
                type:ORG_ADMIN_MANAGE_USER_RESET
            })
        }
    },[success])
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Add User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='modal-body'>
                <form>
                    <Row className="mb-3" controlId="firstName">
                        <Col sm='3'><label>Name</label></Col>
                        <Col sm="9">
                            <input 
                                autoFocus
                                type='text'
                                className='modal-input-box'
                                value={firstName}
                                required
                                onChange={(event)=>setFirstName(event.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3" controlId="email">
                        <Col sm='3'><label>Email</label> </Col>
                        <Col sm="9">
                            <input 
                                autoFocus
                                type='email'
                                className='modal-input-box'
                                value={email}
                                required
                                onChange={(event)=>setEmail(event.target.value)}
                            />
                        </Col>
                    </Row>
                    {/* <Row className="mb-3" controlId="surname">
                        <Col sm='3'><label>Password</label></Col>
                        <Col sm="9">
                            <input 
                                autoFocus
                                className='modal-input-box'
                                type='password'
                                value={password}
                                required
                                onChange={(event)=>setPassword(event.target.value)}
                            />
                        </Col>
                    </Row> */}
                    <div className='btn-centre'>
                        {
                            processing
                            ?
                            <button className='protondx-btn-primary btn-lg btn-align' disabled={true}>Adding new user...
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </button>
                            :
                            <button className='protondx-btn-primary btn-lg btn-align' onClick={addUser}>Add new user</button>
                        }
                    </div>
                    <div style={{marginTop:'10px'}}>
                         {error && <Alerts type="danger" message={error} />}
                    </div>
                    </form>
                    </div>
                </Modal.Body>               
            </Modal>
        </div>
    )
}

export default AddUser
