import React, {useEffect, useState} from 'react'
import {Modal, Button, Form, Row, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addProtondxAdminOrg } from '../../../redux-store/actions/protondxAdminActions'
import { PROTONDX_ADMIN_MANAGE_ORG_RESET } from '../../../redux-store/types'
import Alerts from '../../partials/Alerts'
import { Spinner } from 'react-bootstrap'

const AddOrg = (props) => {

    const [orgName, setOrgName] = useState()
    const [orgRepName, setOrgRepName] = useState()
    const [orgRepEmail, setOrgRepEmail] = useState()
 

    const {processing, success, error} = useSelector((state)=>state.protondxAdminManageOrg)
    const dispatch = useDispatch()

    const resetState = ()=>{
        setOrgName()
        setOrgRepName()
        setOrgRepEmail()
    }
    const addOrganization = async (e)=>{
        e.preventDefault()
        if(orgName && orgRepName && orgRepEmail){
            await dispatch(addProtondxAdminOrg(orgName,orgRepName, orgRepEmail))

        }                  
    }

    useEffect(()=>{
        if(success){
            // Clear the form
            resetState()
            props.manageOrganization()
            props.onHide()
            dispatch({
                type:PROTONDX_ADMIN_MANAGE_ORG_RESET
            })
        }
    },[success])
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Add Organization
                    </Modal.Title>
                </Modal.Header>
                
                    <Modal.Body>
                    <div className='modal-body'>
                    <form>
                        <Row className="mb-3" controlId="orgName">
                            <Col sm='3'>
                                <label >Organization Name </label>
                            </Col>
                            <Col sm="9">
                                <input 
                                    autoFocus
                                    type='text'
                                    value={orgName}
                                    required
                                    onChange={(event)=>setOrgName(event.target.value)}
                                    className='modal-input-box'
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3" controlId="orgRepName">
                            <Col sm='3'>
                                <label column sm="3">Representative Name</label>
                            </Col>    
                            <Col sm="9">
                                <input 
                                    autoFocus
                                    type='text'
                                    value={orgRepName}
                                    required
                                    onChange={(event)=>setOrgRepName(event.target.value)}
                                    className='modal-input-box'
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3" controlId="formPlaintextPassword">
                            <Col sm='3'>
                            <label column sm="3">Representative Email</label>
                            </Col> 
                            <Col sm="9">
                                <input 
                                    autoFocus
                                    type='email'
                                    value={orgRepEmail}
                                    required
                                    onChange={(event)=>setOrgRepEmail(event.target.value)}
                                    className='modal-input-box'
                                />
                            </Col>
                        </Row>
                        <div className='btn-centre'>
                            {
                                processing?
                                <button className='protondx-btn-primary btn-lg' disabled={true}>Adding new organization...
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                                :
                                <button className='protondx-btn-primary btn-lg' onClick={addOrganization}>Add new organization</button>
                            }
                        </div>
                        <div style={{marginTop:'10px'}}>
                            {error && <Alerts type="danger" message={error} />}
                        </div>
                        </form>
                       
                        </div>
                    </Modal.Body>
                    
                    
            </Modal>
        </div>
    )
}

export default AddOrg
